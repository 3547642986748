import { convertYearToUnixTimestamp } from "../../common/helpers/date";
import { VEHICLE_FACETS } from "../../algolia/services/vehicleFacetKeys";
import { CURRENT_YEAR } from "../../common/constants/currentYear";
import { FilterDefinition, GroupSelectFilterDef, RangeFilterDef } from "./types/filterDefinitionTypes";

export const VEHICLE_CONDITION_QUERY_PARAM = "vehicleCondition";
export const VEHICLE_CLASSES_QUERY_PARAM = "vehicleClasses";
export const VEHICLE_TYPES_QUERY_PARAM = "vehicleTypes";
export const DRIVE_TYPES_QUERY_PARAM = "driveTypes";
export const OTHER_FILTERS_QUERY_PARAM = "otherFilters";
export const BRAND_MODEL_QUERY_PARAM = "brandModel";
export const BODY_TYPE_QUERY_PARAM = "bodyType";
export const FUEL_TYPE_QUERY_PARAM = "fuelType";
export const TRANSMISSION_QUERY_PARAM = "transmission";
export const FIRST_REGISTRATION_QUERY_PARAM = "firstRegistration";
export const POWER_QUERY_PARAM = "power";
export const OPTIONS_QUERY_PARAM = "options";
export const PROMOTIONS_QUERY_PARAM = "promotions";
export const EXTERIOR_COLOR_QUERY_PARAM = "exteriorColor";
export const INTERIOR_COLOR_QUERY_PARAM = "interiorColor";
export const UPHOLSTERY_QUERY_PARAM = "upholstery";
export const EMISSION_CLASS_QUERY_PARAM = "emissionClass";
export const PRICE_QUERY_PARAM = "price";
export const MILEAGE_QUERY_PARAM = "mileage";
export const DOOR_NUM_QUERY_PARAM = "doors";
export const SEAT_NUM_QUERY_PARAM = "seats";
export const CCM_QUERY_PARAM = "ccm";
export const CYLINDER_QUERY_PARAM = "cylinders";
export const WEIGHT_MAX_QUERY_PARAM = "trailerWeight";
export const TRAILER_WEIGHT_MAX_QUERY_PARAM = "trailerWeightMax";
export const EMPTY_WEIGHT_QUERY_PARAM = "emptyWeight";
export const BATTERY_CAPACITY_QUERY_PARAM = "batteryCapacity";
export const BATTERY_RANGE_QUERY_PARAM = "batteryRange";
export const GEO_QUERY_PARAM = "geo";

export enum VEHICLE_CONDITION_INSIDE_IDS {
  NEW = "1",
  USED = "2",
}

const psToKw = (ps: number): number => Math.round(ps * 0.735499);
const kwToPs = (kw: number): number => Math.round(kw / 0.735499);

const MIN_PS = 50;
const MAX_PS = 750;

export const RANGE_FILTER_DEFINITIONS: Record<string, RangeFilterDef> = {
  [FIRST_REGISTRATION_QUERY_PARAM]: {
    type: "range",
    title: t => t("FIRST REGISTRATION"),
    queryParam: FIRST_REGISTRATION_QUERY_PARAM,
    units: {
      scale: "linear",
      max: () => CURRENT_YEAR,
      min: () => 1950,
      facetKey: VEHICLE_FACETS.FILTER_FIRST_REGISTRATION,
      convert: (y, isLower) => {
        // min & max should be inclusive
        return convertYearToUnixTimestamp(isLower ? y : y + 1);
      },
      formatting: "year",
    },
  },
  [POWER_QUERY_PARAM]: {
    type: "range",
    title: t => t("POWER UNIT"),
    queryParam: POWER_QUERY_PARAM,
    units: [
      {
        id: "ps",
        label: t => t("PS"),
        scale: "log",
        unit: t => t("PS"),
        max: () => MAX_PS,
        min: () => MIN_PS,
        facetKey: VEHICLE_FACETS.PS,
        precision: 5,
        formatting: "integer",
      },
      {
        id: "kw",
        label: t => t("KW"),
        scale: "log",
        unit: t => t("KW"),
        max: () => psToKw(MAX_PS),
        min: () => psToKw(MIN_PS),
        facetKey: VEHICLE_FACETS.PS,
        convert: kwToPs,
        precision: 5,
        formatting: "integer",
      },
    ],
  },
  [PRICE_QUERY_PARAM]: {
    type: "range",
    title: t => t("PRICE"),
    queryParam: PRICE_QUERY_PARAM,
    units: [
      {
        id: "b",
        label: t => t("BUY PRICE"),
        scale: "log",
        unit: t => t("CHF"),
        max: () => 500_000,
        min: () => 1000,
        facetKey: VEHICLE_FACETS.PRICE_B2C_GROSS,
        precision: 1000,
        formatting: "integer",
      },
      {
        id: "l",
        label: t => t("MONTHLY RATE"),
        scale: "log",
        unit: t => t("CHF"),
        max: () => 7500,
        min: () => 100,
        facetKey: VEHICLE_FACETS.LEASING_RATE_FROM,
        precision: 10,
        formatting: "integer",
      },
    ],
  },
  [MILEAGE_QUERY_PARAM]: {
    type: "range",
    title: t => t("MILEAGE"),
    queryParam: MILEAGE_QUERY_PARAM,
    units: {
      scale: "log",
      unit: t => t("KM"),
      max: () => 250_000,
      min: () => 1000,
      facetKey: VEHICLE_FACETS.MILEAGE,
      precision: 1000,
      formatting: "integer",
    },
  },
  [DOOR_NUM_QUERY_PARAM]: {
    type: "range",
    title: t => t("DOOR NUM"),
    queryParam: DOOR_NUM_QUERY_PARAM,
    units: {
      scale: "linear",
      min: () => 1,
      max: () => 6,
      facetKey: VEHICLE_FACETS.DOOR_NUM,
      formatting: "integer",
    },
  },
  [SEAT_NUM_QUERY_PARAM]: {
    type: "range",
    title: t => t("SEAT NUM"),
    queryParam: SEAT_NUM_QUERY_PARAM,
    units: {
      scale: "linear",
      min: () => 1,
      max: () => 15,
      facetKey: VEHICLE_FACETS.SEAT_NUM,
      formatting: "integer",
    },
  },
  [CCM_QUERY_PARAM]: {
    type: "range",
    title: t => t("ENGINE CAPACITY"),
    queryParam: CCM_QUERY_PARAM,
    units: {
      scale: "linear",
      unit: t => t("CCM"),
      min: () => 500,
      max: () => 8000,
      facetKey: VEHICLE_FACETS.CCM,
      precision: 10,
      formatting: "integer",
    },
  },
  [CYLINDER_QUERY_PARAM]: {
    type: "range",
    title: t => t("CYLINDER"),
    queryParam: CYLINDER_QUERY_PARAM,
    units: {
      scale: "linear",
      min: () => 1,
      max: () => 12,
      facetKey: VEHICLE_FACETS.CYLINDER_NUM,
      formatting: "integer",
    },
  },
  [WEIGHT_MAX_QUERY_PARAM]: {
    type: "range",
    title: t => t("WEIGHT MAX"),
    queryParam: WEIGHT_MAX_QUERY_PARAM,
    units: {
      unit: t => t("KG"),
      scale: "linear",
      min: () => 500,
      max: () => 5000,
      facetKey: VEHICLE_FACETS.WEIGHT_MAX,
      precision: 100,
      formatting: "integer",
    },
  },
  [TRAILER_WEIGHT_MAX_QUERY_PARAM]: {
    type: "range",
    title: t => t("TRAILER WEIGHT MAX"),
    queryParam: TRAILER_WEIGHT_MAX_QUERY_PARAM,
    units: {
      unit: t => t("KG"),
      scale: "linear",
      min: () => 500,
      max: () => 3500,
      facetKey: VEHICLE_FACETS.TRAILER_WEIGHT_MAX,
      precision: 100,
      formatting: "integer",
    },
  },
  [EMPTY_WEIGHT_QUERY_PARAM]: {
    type: "range",
    title: t => t("EMPTY WEIGHT"),
    queryParam: EMPTY_WEIGHT_QUERY_PARAM,
    units: {
      unit: t => t("KG"),
      scale: "linear",
      min: () => 500,
      max: () => 3500,
      facetKey: VEHICLE_FACETS.EMPTY_WEIGHT,
      precision: 100,
      formatting: "integer",
    },
  },
  [BATTERY_CAPACITY_QUERY_PARAM]: {
    type: "range",
    title: t => t("BATTERY CAPACITY"),
    queryParam: BATTERY_CAPACITY_QUERY_PARAM,
    units: {
      unit: t => t("KWH"),
      scale: "linear",
      min: () => 50,
      max: () => 2500,
      facetKey: VEHICLE_FACETS.BATTERY_CAPACITY,
      precision: 10,
      formatting: "integer",
    },
  },
  [BATTERY_RANGE_QUERY_PARAM]: {
    type: "range",
    title: t => t("BATTERY RANGE"),
    queryParam: BATTERY_RANGE_QUERY_PARAM,
    units: {
      unit: t => t("WLTP"),
      scale: "linear",
      min: () => 10,
      max: () => 1000,
      facetKey: VEHICLE_FACETS.BATTERY_RANGE,
      precision: 10,
      formatting: "integer",
    },
  },
};

export const GROUP_SELECT_FILTER_DEFINITIONS: Record<string, GroupSelectFilterDef> = {
  [BODY_TYPE_QUERY_PARAM]: {
    type: "groupSelect",
    title: t => t("BODY TYPE"),
    dataKey: "bodyTypes",
    queryParam: BODY_TYPE_QUERY_PARAM,
    childFacetKey: VEHICLE_FACETS.BODY_TYPE,
    groupFacetKey: VEHICLE_FACETS.BODY_TYPE_GROUP,
  },
  [FUEL_TYPE_QUERY_PARAM]: {
    type: "groupSelect",
    title: t => t("FUEL TYPE"),
    dataKey: "fuelTypes",
    queryParam: FUEL_TYPE_QUERY_PARAM,
    groupFacetKey: VEHICLE_FACETS.FUEL_TYPE_GROUP,
    childFacetKey: VEHICLE_FACETS.FUEL_TYPE,
  },
};

// filter definitions contain specific instances of the generic filter types
export const FILTER_DEFINITIONS: Record<string, FilterDefinition> = {
  [BRAND_MODEL_QUERY_PARAM]: {
    type: "brandModel",
    queryParam: BRAND_MODEL_QUERY_PARAM,
  },
  [VEHICLE_CONDITION_QUERY_PARAM]: {
    type: "tag",
    title: t => t("VEHICLE CONDITION"),
    queryParam: VEHICLE_CONDITION_QUERY_PARAM,
    dataKey: "vehicleConditions",
    facetKey: VEHICLE_FACETS.VEHICLE_CONDITION,
  },
  [VEHICLE_CLASSES_QUERY_PARAM]: {
    type: "tag",
    title: t => t("VEHICLE CLASS"),
    dataKey: "vehicleClasses",
    queryParam: VEHICLE_CLASSES_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.VEHICLE_CLASS,
  },
  [VEHICLE_TYPES_QUERY_PARAM]: {
    type: "tag",
    title: t => t("VEHICLE TYPE"),
    dataKey: "vehicleTypes",
    queryParam: VEHICLE_TYPES_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.VEHICLE_TYPE,
  },
  [DRIVE_TYPES_QUERY_PARAM]: {
    type: "tag",
    title: t => t("DRIVE TYPE"),
    dataKey: "driveTypes",
    queryParam: DRIVE_TYPES_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.DRIVE_TYPE,
  },
  [TRANSMISSION_QUERY_PARAM]: {
    type: "tag",
    title: t => t("TRANSMISSION"),
    dataKey: "transmissions",
    queryParam: TRANSMISSION_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.TRANSMISSION_GROUP,
  },
  [OTHER_FILTERS_QUERY_PARAM]: {
    type: "tag",
    title: t => t("OTHER FILTERS"),
    dataKey: "otherFilterTags",
    queryParam: OTHER_FILTERS_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.OTHER_FILTER_TAGS,
    conjunctive: true,
  },
  [OPTIONS_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("EQUIPMENT"),
    dataKey: "optionFacets",
    queryParam: OPTIONS_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.OPTIONS,
    conjunctive: true,
  },
  [PROMOTIONS_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("PROMOTION"),
    dataKey: "promotions",
    queryParam: PROMOTIONS_QUERY_PARAM,
    facetKey: VEHICLE_FACETS.PROMOTIONS,
    getSearchValue: id => id,
  },
  [EXTERIOR_COLOR_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("EXTERIOR COLOR"),
    dataKey: "exteriorColors",
    facetKey: VEHICLE_FACETS.EXTERIOR_COLOR,
    queryParam: EXTERIOR_COLOR_QUERY_PARAM,
  },
  [INTERIOR_COLOR_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("INTERIOR COLOR"),
    dataKey: "interiorColors",
    facetKey: VEHICLE_FACETS.INTERIOR_COLOR,
    queryParam: INTERIOR_COLOR_QUERY_PARAM,
  },
  [UPHOLSTERY_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("UPHOLSTERY"),
    dataKey: "upholsteries",
    facetKey: VEHICLE_FACETS.UPHOLSTERY,
    queryParam: UPHOLSTERY_QUERY_PARAM,
  },
  [EMISSION_CLASS_QUERY_PARAM]: {
    type: "multiSelect",
    title: t => t("EMISSION CLASS"),
    dataKey: "emissionClasses",
    facetKey: VEHICLE_FACETS.EMISSION_CLASS,
    queryParam: EMISSION_CLASS_QUERY_PARAM,
  },
  [GEO_QUERY_PARAM]: {
    type: "geo",
    queryParam: GEO_QUERY_PARAM,
  },
  ...GROUP_SELECT_FILTER_DEFINITIONS,
  ...RANGE_FILTER_DEFINITIONS,
};

export const BASE_FILTERS = [
  VEHICLE_CONDITION_QUERY_PARAM,
  BRAND_MODEL_QUERY_PARAM,
  PRICE_QUERY_PARAM,
  GEO_QUERY_PARAM,
  FIRST_REGISTRATION_QUERY_PARAM,
  MILEAGE_QUERY_PARAM,
  FUEL_TYPE_QUERY_PARAM,
  TRANSMISSION_QUERY_PARAM,
];

export const ALL_FILTERS = [
  ...BASE_FILTERS,
  BODY_TYPE_QUERY_PARAM,
  OTHER_FILTERS_QUERY_PARAM,
  VEHICLE_CLASSES_QUERY_PARAM,
  VEHICLE_TYPES_QUERY_PARAM,
  DRIVE_TYPES_QUERY_PARAM,
  OPTIONS_QUERY_PARAM,
  PROMOTIONS_QUERY_PARAM,
  POWER_QUERY_PARAM,
  DOOR_NUM_QUERY_PARAM,
  SEAT_NUM_QUERY_PARAM,
  CCM_QUERY_PARAM,
  CYLINDER_QUERY_PARAM,
  BATTERY_CAPACITY_QUERY_PARAM,
  BATTERY_RANGE_QUERY_PARAM,
  WEIGHT_MAX_QUERY_PARAM,
  TRAILER_WEIGHT_MAX_QUERY_PARAM,
  EMPTY_WEIGHT_QUERY_PARAM,
  EXTERIOR_COLOR_QUERY_PARAM,
  INTERIOR_COLOR_QUERY_PARAM,
  UPHOLSTERY_QUERY_PARAM,
  EMISSION_CLASS_QUERY_PARAM,
];
